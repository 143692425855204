import LayoutA4 from "../../components/layout-a4"
import  LayoutA4Main from '../../components/layout-a4-main'
import React from "react"
import '../themeA1/raceInfo.css'
import infoStyles from './a4_raceInfo.module.css'


export default class A4RaceInfo extends React.Component {
  render() {
    console.log("this.props:",this.props)
    const infotype = this.props.pageContext.element.infotype
    console.log("infotype",infotype)
    const topTitle=infotype==='rules'?'竞赛规程':'赛事路线'
    const raceName='2019簇格马拉松竞赛规程'
    const contents=this.props.pageContext.element.contents

    const contentlist=contents.map((content,index)=>
      <div key={index} className={infoStyles.content}>
        <p>{content.title}</p>
        {content.content.map((item,index)=>
          <div key={index}>
            {item.type==='text'&&<p key={index}>{item.text}</p>}
            {item.type==='img'&&<img alt="hello" key={index}  src={item.src}/>}
          </div>

        )}
      </div>)
    return(
      <LayoutA4 location={this.props.location} >
        <LayoutA4Main>
          <div className={infoStyles.rules}>
            <h4>{topTitle}</h4>
            <p className={infoStyles.raceName}>{raceName}</p>
            <div>{contentlist}</div>
          </div>
        </LayoutA4Main>
      </LayoutA4>
    )
  }
}
